










import Vue from 'vue';
import FooterApp from '@/ui/components/FooterApp.vue';
import Navbar from './ui/components/NavbarApp.vue';

export default Vue.extend({
  components: {
    Navbar,
    FooterApp,
  },
  data() {
    return {
      links: [
        {
          name: 'Home',
          url: 'Home',
        },
        {
          name: 'Calculateur',
          url: 'forms',
        },
      ],
    };
  },
  computed: {
    padding() {
      return `pa-${this.$vuetify.breakpoint.width > 600 ? 4 : 0}`;
    },
  },
});
