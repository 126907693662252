







import Vue from 'vue';
import NavbarCat from '../components/NavbarCat.vue';

export default Vue.extend({
  components: {
    NavbarCat,
  },
  data() {
    return {
      width_small: 620,
      categories: [
        'Transports',
        'Logement',
        'Alimentation',
        'Consommation',
        'Résultats',
      ],
    };
  },
});
