




















import Vue from 'vue';

interface Icon {
  name: string
  mdiName: string
  link: string
}

export default Vue.extend({
  data() {
    return {
      icons: [
        {
          name: 'Github',
          mdiName: 'mdi-github',
          link: 'https://github.com/thomas-god/ges',
        },
        {
          name: 'Twitter',
          mdiName: 'mdi-twitter',
          link: 'https://twitter.com/combiendeco2',
        },
        {
          name: 'Mail',
          mdiName: 'mdi-email',
          link: 'mailto:contact@combiendecarbone.fr',
        },
      ] as Icon[],
    };
  },
});
