









import Vue from 'vue';
import NavbarCat from '../components/NavbarCat.vue';

export default Vue.extend({
  components: {
    NavbarCat,
  },
  data() {
    return {
      width_small: 700,
      categories: [
        'Général',
        'Transports',
        'Logement',
        'Alimentation',
        'Consommation',
        // 'Résultats'
      ],
    };
  },
});
